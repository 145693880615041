import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';


// // Importing the Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css'
// import Amplify from "aws-amplify";
// import awsExports from "./aws-exports";
// Amplify.configure(awsExports);


ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
