import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, MemoryRouter, Switch, Route, Redirect, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import { LinkContainer } from 'react-router-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import {CardDeck} from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import Nav from 'react-bootstrap/Nav'
import Media from 'react-bootstrap/Media'
import FormControl from 'react-bootstrap/FormControl'
import Form from 'react-bootstrap/Form'
import Carousel from 'react-bootstrap/Carousel'

// import Login, {BusinessList, OrgsList} from "./Login";
// import polpos from './secure/politics'
// import {AllPosts, PolicyPosts, TransitPosts, HousingPosts, PoliticsPosts, VoterGuidePosts, ArtsPosts, EventsList} from "./Login"
// import './App.css';
// import {Typeahead} from "react-bootstrap-typeahead";
// import {MentorshipPage} from "./Mentorship";
// //import IMG_6250 from './IMG_6250.JPG'
// //import IMG_6251 from './IMG_6251.JPG'
// //import IMG_6252 from './IMG_6252.JPG'
// //import IMG_6253 from './IMG_6253.JPG'
// //import IMG_6254 from './IMG_6254.JPG'


// class IconButton extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isCardView: false,
//         }
//     }

//     render() {
//         return (
//             <a className="btn btn-primary" style={{backgroundColor:"rgb(153, 157, 209)", border:"none", margin:'0', padding:'0'}} onClick={()=>this.setState({ isCardView: !this.state.isCardView })}>
//                 { this.state.isCardView
//                     ? <ArrowUp />
//                     : <ArrowDown />
//                 }
//                 &nbsp;&nbsp;Community Efforts
//             </a>
//         );
//     }

// }

// class SwitchIcon extends React.Component {
//     render () {
//         return (
//             <div style={{margin:'0', padding:'0'}}>
//                 <IconButton />
//             </div>
//         );
//     }
// }

// const ArrowDown = () => {
//     return(
//         <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="white"
//              xmlns="http://www.w3.org/2000/svg">
//             <path fill-rule="evenodd"
//                   d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
//         </svg>
//     )
// }

// const ArrowUp = () => {
//     return(
//         <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-up" fill="white"
//              xmlns="http://www.w3.org/2000/svg">
//             <path
//                   d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
//         </svg>
//     )
// }

// const AccordionDefault = () => {

//     return(
//         <Accordion>
//             <div class="text-right">
//                 <Accordion.Toggle as={Button} variant="link" eventKey="2" style={{color:'white', textDecoration:'none'}}>
//                      <SwitchIcon style={{margin: '0', padding:'0'}}/>
//                 </Accordion.Toggle>
//                 <Accordion.Collapse eventKey="2">
//                     <LinkContainer to="/Policy">
//                         <Card.Body>
//                             <Card.Link className='hamburger-link' href="/Policy">Policy</Card.Link>
//                         </Card.Body>
//                     </LinkContainer>
//                 </Accordion.Collapse>
//                 <Accordion.Collapse eventKey="2">
//                     <LinkContainer to="/Transit">
//                         <Card.Body>
//                             <Card.Link className='hamburger-link' href="/Transit">Transit</Card.Link>
//                         </Card.Body>
//                     </LinkContainer>
//                 </Accordion.Collapse>
//                 <Accordion.Collapse eventKey="2">
//                     <LinkContainer to="/Housing">
//                         <Card.Body>
//                             <Card.Link className='hamburger-link' href="/HC">Housing</Card.Link>
//                         </Card.Body>
//                     </LinkContainer>
//                 </Accordion.Collapse>
//                 <Accordion.Collapse eventKey="2">
//                     <LinkContainer to="/Politics">
//                         <Card.Body>
//                             <Card.Link className='hamburger-link' href="/Politics">Politics</Card.Link>
//                         </Card.Body>
//                     </LinkContainer>
//                 </Accordion.Collapse>
//                 <Accordion.Collapse eventKey="2">
//                     <LinkContainer to="/Mentorship">
//                         <Card.Body>
//                             <Card.Link className='hamburger-link' href="/Mentorship">Mentorship</Card.Link>
//                         </Card.Body>
//                     </LinkContainer>
//                 </Accordion.Collapse>
//                 <Accordion.Collapse eventKey="2">
//                     <LinkContainer to="/Arts">
//                         <Card.Body>
//                             <Card.Link className='hamburger-link' href="/MA">Music & the Arts</Card.Link>
//                         </Card.Body>
//                     </LinkContainer>
//                 </Accordion.Collapse>
//                 <Accordion.Collapse eventKey="2">
//                     <LinkContainer to="/Voter Guide">
//                         <Card.Body>
//                             <Card.Link className='hamburger-link' href="/Voter Guide">Voter Guide</Card.Link>
//                         </Card.Body>
//                     </LinkContainer>
//                 </Accordion.Collapse>
//             </div>
//         </Accordion>
//     )
// }

// export const AccordionNested = () => {
//     return(
//         <Accordion>
//             <div className="text-right">
//                 <Card className="hamburger-menu" style={{backgroundColor:'#999DD1'}} text={'white'}>
//                     <Card.Header>
//                         <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color:'white', textDecoration:'none'}}>
//                             <span role="img" aria-label="tada">
//                                 <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-list"
//                                      fill="white" xmlns="http://www.w3.org/2000/svg">
//                                   <path fill-rule="evenodd"
//                                         d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
//                                 </svg>
//                             </span>
//                         </Accordion.Toggle>
//                     </Card.Header>
//                     <Accordion.Collapse eventKey="0">
//                         <LinkContainer to="/Home">
//                             <Card.Body>
//                                 <Card.Link className='hamburger-link' href="/Home">Home</Card.Link>
//                             </Card.Body>
//                         </LinkContainer>
//                     </Accordion.Collapse>
//                     <Accordion.Collapse eventKey="0">
//                         <Card.Body>
//                             <AccordionDefault />
//                         </Card.Body>
//                     </Accordion.Collapse>
//                     <Accordion.Collapse eventKey="0">
//                         <LinkContainer to="/About">
//                             <Card.Body>
//                                 <Card.Link className='hamburger-link' href="/About">About</Card.Link>
//                             </Card.Body>
//                         </LinkContainer>
//                     </Accordion.Collapse>
//                     <Accordion.Collapse eventKey="0">
//                         <LinkContainer to="/Connect">
//                             <Card.Body>
//                                 <Card.Link className='hamburger-link' href="/Connect">Volunteer</Card.Link>
//                             </Card.Body>
//                         </LinkContainer>
//                     </Accordion.Collapse>
//                     <Accordion.Collapse eventKey="0">
//                         <LinkContainer to="/Contact">
//                             <Card.Body>
//                                 <Card.Link className='hamburger-link' href="/Contact">Contact</Card.Link>
//                             </Card.Body>
//                         </LinkContainer>
//                     </Accordion.Collapse>
//                     <Accordion.Collapse eventKey="0">
//                         <LinkContainer to="/Login">
//                             <Card.Body>
//                                 <Button href ="/Login" className = 'rounded-pill' style={{backgroundColor:'#E1E2EF', color:'black', border:'none'}}>
//                                     Login
//                                 </Button>
//                             </Card.Body>
//                         </LinkContainer>
//                     </Accordion.Collapse>
//                 </Card>
//             </div>
//         </Accordion>
//     )
// }

// const ButtonStrip = () => {
//     return(
//         <Nav justify className = 'navbar' variant="tabs" color="red" defaultActiveKey="/Home/SN">
//             <LinkContainer to="/Home/SN">
//                 <Nav.Item>
//                     <Nav.Link href="/Home/SN">Home</Nav.Link>
//                 </Nav.Item>
//             </LinkContainer>
//             <LinkContainer to="/Home/Events">
//                 <Nav.Item>
//                     <Nav.Link href="/Home/Events">Events</Nav.Link>
//                 </Nav.Item>
//             </LinkContainer>
//             <LinkContainer to="/Home/Business">
//                 <Nav.Item>
//                     <Nav.Link href="/Home/Business">Businesses</Nav.Link>
//                 </Nav.Item>
//             </LinkContainer>
//             <LinkContainer to="/Home/Orgs">
//                 <Nav.Item>
//                     <Nav.Link href="/Home/Orgs">Organizations</Nav.Link>
//                 </Nav.Item>
//             </LinkContainer>
//         </Nav>
//     )
// }

// export function Iframe(props) {
//     return (
//         <span dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} style={{width:"100%"}}/>
//         );
// }


// export const iframes = {
//     calendar:
//         '<iframe name = "calendar" src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23e1e2ef&amp;ctz=America%2FLos_Angeles&amp;src=bmFpdDRwNHJhNTZ2djc3ZHZiZjNsYWZraWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23E67C73&amp;showTitle=0" style="border-width:0; body{font-family: \'Montserrat\', sans-serif !important;}" width="800" height="600" frameborder="0" scrolling="no"></iframe>',
//     eventMap:
//         '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d81608.65942484082!2d-121.87895490987323!3d37.32909079332515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1597008459104!5m2!1sen!2sus" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
//     mentorSurvey:
//         '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfqIdKTkHQaO1W725EY6zDyb2zyvgF9MC62LuVcXYbOAUKdVg/viewform?embedded=true" width="640px" height="640px" frameborder="0" marginheight="0" marginwidth="0" allowfullscreen=""></iframe>'
// }

// const Events = () => {

//     return(
//         <MemoryRouter>
//             <Switch>
//                 <Route exact path="/" component={() => <Redirect to="/Home/Events" />}/>
//                 <Route path="/Home/Events">
//                     <div className="row">
//                         <Col xs={4} md={4}>
//                             <EventsList />
//                             <br />
//                         </Col>
//                             <Iframe iframe={iframes["eventMap"]} />
//                     </div>
//                 </Route>
//                 <Route path="/Home/EventsCalendar">
//                     <div className="row" style={{height:'400px'}}>
//                         <Iframe iframe={iframes["calendar"]} />
//                     </div>
//                 </Route>
//             </Switch>
//         </MemoryRouter>
//     )
// }

// const Business = () => {
//     return(
//         <div className="row">
//                 <Col xs={4} md={4}>
//                     <BusinessList />
//                 </Col>
//             <Iframe iframe={iframes["eventMap"]} />
//         </div>
//     )
// }

// const Orgs = () => {
//     return(
//         <div className="container-fluid">
//             <OrgsList />
//         </div>
//     )
// }

// const SN = () => {
//     return(
//         <AllPosts />
//     )
// }


// const MS = () => {
//     return(
//         <div>
//             <div className='mission-statement'>
//                 <div style={{fontSize:"100%"}}><strong>Mission Statement</strong></div>
//                 San José Strong is a grassroots platform to reinvent San José for and by the community. Where vacation and visit platforms, like Visit San José, serve as sparknotes for tourists. We are the whole book for residents. Imagine you’ve just moved to San José and want to get involved in the culture and community. You come to our platform and have the tools to do so, whether by getting connected to the awesome organizations and businesses of South Bay, finding accessible resources to understand the landscape of San José, or joining San José Strong’s in house efforts.
//             </div>
//             <br />
//             <div class='container' style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
//                 <LinkContainer to="/Connect">
//                     <Button href ="/Connect" className = 'rounded-button rounded-pill'>
//                         Volunteer
//                     </Button>
//                 </LinkContainer>
//             </div>
//         </div>
// )
// }

// const LogoBar = () => {
//     return(
//         <div class = "jumbotron jumbotron2 jumbotron-fluid">
//             <Media>
//                 <Media.Body>
//                     <div className = 'title'>
//                         <strong>San José Strong</strong>
//                     </div>
//                     <br />
//                     <div className = 'subtitle'>
//                         A grassroots platform to reinvent San José for and by the community
//                     </div>
//                 </Media.Body>
//                 <img
//                     width={"25%"}
//                     height={"25%"}
//                     className="ml-3"
//                     src="https://raw.githubusercontent.com/SanJoseStrong/sanjosestrong.github.io/master/Mask_Group_3.png"
//                     alt="Generic placeholder"
//                 />
//             </Media>
//         </div>
//     )
// }

// const LogoBar2 = () => {
//     return(
//         <div class = "jumbotron jumbotron2 jumbotron-fluid">
//             <Media>
//                 <Media.Body>
//                     <br />
//                     <div className = 'title'>
//                         <strong>San José Strong</strong>
//                     </div>
//                 </Media.Body>
//                 <img
//                     width={"25%"}
//                     height={"25%"}
//                     className="ml-3 logo-bar-2"
//                     src="https://raw.githubusercontent.com/SanJoseStrong/sanjosestrong.github.io/master/Mask_Group_3.png"
//                     alt="Generic placeholder"
//                 />
//             </Media>
//         </div>
//     )
// }

// const Footer = () => {
//     return(
//         <div>
//             <br />
//             San Jose Strong © 2020
//         </div>
//     )
// }

// const HomePage = () => {
//     return(
//         <div>
//             <AccordionNested />
//             <LogoBar />
//             <div className ="jumbotron jumbotron-fluid jumbotron1">
//                 <ButtonStrip />
//                 <Route path="/" component={App}>
//                     <Switch>
//                         <Route exact path="/Home" component={() => <Redirect to="/Home/SN" />}/>
//                         <Route path="/Home/SN">
//                             <SN />
//                         </Route>
//                         <Route path="/Home/Events">
//                             <Events/>
//                         </Route>
//                         <Route path="/Home/Business">
//                             <Business />
//                         </Route>
//                         <Route path="/Home/Orgs">
//                             <Orgs />
//                         </Route>
//                     </Switch>
//                 </Route>
//             </div>
//             <div class="jumbotron jumbotron2 jumbotron-fluid" style={{border:"1px #E1E2EF solid"}}>
//                 <MS />
//             </div>
//             <div className="jumbotron jumbotron3 jumbotron-fluid">
//                 <Footer />
//             </div>
//         </div>
//     )
// }

// const About = () => {
//     return(
//         <div>
//             <AccordionNested />
//             <LogoBar2 />
//             <div className ="jumbotron jumbotron-fluid jumbotron1">
//                 <div className="page-text">
//                     <div className="page-title page-text">
//                         About Us
//                     </div>
//                     <div className="page-subtitle page-text">
//                         Mission Statement
//                     </div>
//                     <div className="page-text page-body">
//                         San José Strong is a grassroots platform to reinvent San José for and by the community. Where vacation and visit platforms, like Visit San José, serve as sparknotes for tourists. We are the whole book for residents. Imagine you’ve just moved to San José and want to get involved in the culture and community. You come to our platform and have the tools to do so, whether by getting connected to the awesome organizations and businesses of South Bay, finding accessible resources to understand the landscape of San José, or joining San José Strong’s in house efforts.
//                     </div>
//                     <div className="page-subtitle page-text">
//                         Vision Statement
//                     </div>
//                     <div className="page-text page-body">
//                         A world where every citizen of a city has a place to belong and the space to thrive. A world where our cities, like San José, are genuine communities, where every citizen has the tools and resources to optimize their skills, make their voice heard, and positively benefit the community around them.
//                     </div>
//                     <div className="page-subtitle page-text">
//                         8 Core Values
//                     </div>
//                     <div className="page-text page-body">
//                         <strong>Accountability:</strong> Should San José Strong make a mistake, we want the community to hold us accountable so we can do our utmost to meet the needs of the community effectively and positively. We take responsibility for our actions as an organization and the consequential impact and consequences—even when those are negative.
//                     </div>
//                     <div className="page-text page-body">
//                         <strong>Continuous Learning:</strong> We believe that the process of improvement is never-ending. The work San José Strong has picked up has been going on for centuries before us and will continue for centuries more. We want to stand alongside movements for progressive change in our community and continuously evolve as an organization.
//                     </div>
//                     <div className="page-text page-body">
//                         <strong>Diversity:</strong> In order to get the most comprehensive solutions to a problem, we must have diversity of thought. Our platform thus works to uplift the voices and experiences of all members of the San José community to create holistic approaches to problem-solving.
//                     </div>
//                     <div className="page-text page-body">
//                         <strong>Empathy:</strong> We believe that any solution or conversation needs to be centered on those directly impacted by said solution and conversation. We are committed to promoting empathetic community engagement.
//                     </div>
//                     <div className="page-text page-body">
//                         <strong>Empowerment:</strong> We believe that everyone has something to bring to the table. We work to empower our team to take on projects themselves and make a difference in our community.
//                     </div>
//                     <div className="page-text page-body">
//                         <strong>Psychological Safety:</strong> We are committed to creating a psychologically safe environment. We welcome constructive feedback and want all members of our team to feel comfortable. We promote emotional check-ins, time for breaks and hobbies, and showing up for team members both within San José Strong and in each others’ personal lives.
//                     </div>
//                     <div className="page-text page-body">
//                         <strong>Imagination:</strong> We believe that San José can be the home to a new blueprint for how our cities operate and we work to imagine such a future for San Jose through innovation and creativity.
//                     </div>
//                     <div className="page-text page-body">
//                         <strong>Transparency:</strong> We are committed to being transparent with our team and the community at all times. We uphold honesty and directness, we can explain every action taken thoroughly and welcome discourse with the community.
//                     </div>
//                 </div>
//             </div>
//             <div className="jumbotron jumbotron3 jumbotron-fluid">
//                 <Footer />
//             </div>
//         </div>
//     )
// }

// const Submissions = () => {
//     const SubmitLinks = [
//         {Submit: 'Call-for-Support Submission', link: 'https://docs.google.com/forms/d/e/1FAIpQLScPKDmvBFyvcxTp9brXPJCZQxSg71_EXtMXfJjaLuAuxJfQcA/viewform?usp=sf_link'},
//         {Submit: 'Call-to-Action Submission', link: 'https://docs.google.com/forms/d/e/1FAIpQLSd5aKyP52v6HdYyM-SyY8giHj3srWxIjCKdrMw9cpnNueua_w/viewform?usp=sf_link'},
//         {Submit: 'Events & Protests Submission', link: 'https://docs.google.com/forms/d/e/1FAIpQLScq8szLcIjvuQ_59qgiD0FqHMADyy2bXiiF_OQwKGyR-nF8sw/viewform?usp=sf_link'},
//         {Submit: 'Organizations & Businesses Submission', link: 'https://docs.google.com/forms/d/e/1FAIpQLSe6FDRAfSgKm6xlZYmGkCBPXADWE0X9NBQP2DjuRYzcYYCblg/viewform?usp=sf_link'},
//         {Submit: 'Research Topic Submission', link: 'https://docs.google.com/forms/d/e/1FAIpQLSe6FDRAfSgKm6xlZYmGkCBPXADWE0X9NBQP2DjuRYzcYYCblg/viewform?usp=sf_link'}
//     ];

//     return (
//         SubmitLinks.map((val, index) => (
//             <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', margin:'16px'}}>
//                 <LinkContainer to={SubmitLinks[index].link}>
//                     <Button href ={SubmitLinks[index].link} className = 'rounded-button rounded-pill submit-link-list'>
//                         {SubmitLinks[index].Submit}
//                     </Button>
//                 </LinkContainer>
//             </div>
//         ))
//     )
// }
// const Contact = () => {
//     return(
//         <div>
//             <AccordionNested />
//             <LogoBar2 />
//             <div className ="jumbotron jumbotron-fluid jumbotron1">
//                 <div className="page-text">
//                     <div className="page-title page-text">
//                         Contact Us
//                     </div>
//                 </div>
//                 <div className='container'>
//                     <Container style={{justifyContent: 'center', alignItems: 'center', display: 'flex', marginBottom:'8px'}}>
//                         <Row>
//                             <Col className="mentor-socials-container">
//                                 <a href="https://www.instagram.com/sanjose_strong/">
//                                     <svg className="mentor-socials" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
//                                     </svg>
//                                 </a>
//                             </Col>
//                             <Col className="mentor-socials-container">
//                                 <a href='https://www.facebook.com/San-Jose-Strong-103195288103097/?ref=nf&hc_ref=ARSeKnIiYPIVhQjTWy-3znqFfYRswoMwijBBvdsryshyEscn7eCm2t2tZpTXRkuxPpI'>
//                                     <svg className="mentor-socials" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg>
//                                 </a>
//                             </Col>
//                             <Col className="mentor-socials-container">
//                                 <a href='mailto:sanjose4blacklives@gmail.com'>
//                                     <svg className="mentor-socials" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.288 21h-20.576c-.945 0-1.712-.767-1.712-1.712v-13.576c0-.945.767-1.712 1.712-1.712h20.576c.945 0 1.712.767 1.712 1.712v13.576c0 .945-.767 1.712-1.712 1.712zm-10.288-6.086l-9.342-6.483-.02 11.569h18.684v-11.569l-9.322 6.483zm8.869-9.914h-17.789l8.92 6.229s6.252-4.406 8.869-6.229z"/></svg>
//                                 </a>
//                             </Col>
//                         </Row>
//                     </Container>
//                 </div>
//                 <hr />
//                 <div className="page-text" style={{paddingBottom:'48px'}}>
//                     <div className="page-title page-text">
//                         <br />
//                         Submissions
//                         <br />
//                     </div>
//                         <Submissions />
//                 </div>
//             </div>
//             <div className="jumbotron jumbotron3 jumbotron-fluid">
//                 <Footer />
//             </div>
//         </div>
//     )
// }

// const Connect = () => {
//     return(
//         <div>
//             <AccordionNested />
//             <LogoBar2 />
//             <div className ="jumbotron jumbotron-fluid jumbotron1">
//                 <div className="page-text" style={{marginLeft:'0px', marginRight:'0px'}}>
//                     <div className="page-title page-text">
//                         Volunteer Guide
//                     </div>
//                     <Carousel style={{paddingBottom:'150px'}}>
//                         <Carousel.Item>
//                             <img
//                                 className="d-block w-100"
//                                 src={''}
//                                 alt="First slide"
//                             />
//                         </Carousel.Item>
//                         <Carousel.Item>
//                             <img
//                                 className="d-block w-100"
//                                 src={''}
//                                 alt="Third slide"
//                             />
//                         </Carousel.Item>
//                         <Carousel.Item>
//                             <img
//                                 className="d-block w-100"
//                                 src={''}
//                                 alt="Third slide"
//                             />
//                         </Carousel.Item>
//                         <Carousel.Item>
//                             <img
//                                 className="d-block w-100"
//                                 src={''}
//                                 alt="Fourth slide"
//                             />
//                         </Carousel.Item>
//                         <Carousel.Item>
//                             <img
//                                 className="d-block w-100"
//                                 src={''}
//                                 alt="Fifth slide"
//                             />
//                             <Carousel.Caption>
//                                 <Button className = 'rounded-button rounded-pill'
//                                         href="https://www.tinyurl.com/SJSVolunteerGuide">
//                                         Click Here!
//                                 </Button>
//                             </Carousel.Caption>
//                         </Carousel.Item>
//                     </Carousel>
//                 </div>
//             </div>
//             <div className="jumbotron jumbotron3 jumbotron-fluid">
//                 <Footer />
//             </div>
//         </div>
//     )
// }

// const Policy = () => {
//     return(
//         <div>
//             <AccordionNested />
//             <LogoBar2 />
//             <div className ="jumbotron jumbotron-fluid jumbotron1">
//                 <div className="page-text">
//                     <div className="page-title">
//                         Policy Committee
//                     </div>
//                     <PolicyPosts />
//                 </div>
//             </div>
//             <div className="jumbotron jumbotron3 jumbotron-fluid">
//                 <Footer />
//             </div>
//         </div>
//     )
// }

// const Transit = () => {
//     return(
//         <div>
//             <AccordionNested />
//             <LogoBar2 />
//             <div className ="jumbotron jumbotron-fluid jumbotron1">
//                 <div className="page-text">
//                     <div className="page-title">
//                         Transit in San Jose
//                     </div>
//                     <TransitPosts />
//                     <div className="jumbotron jumbotron2" style={{marginTop:'8px', }}>
//                         <div className='container'
//                              style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
//                             <LinkContainer to="https://www.instagram.com/turnout4transit/">
//                                 <Button href="https://www.instagram.com/turnout4transit/" className='rounded-button rounded-pill'>
//                                     Check out: Turnout4Transit
//                                 </Button>
//                             </LinkContainer>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="jumbotron jumbotron3 jumbotron-fluid">
//                 <Footer />
//             </div>
//         </div>
//     )
// }

// const HC = () => {
//     return(
//         <div>
//             <AccordionNested />
//             <LogoBar2 />
//             <div className ="jumbotron jumbotron-fluid jumbotron1">
//                 <div className="page-text">
//                     <div className="page-title">
//                         Housing Committee
//                     </div>
//                     <HousingPosts />
//                 </div>
//             </div>
//             <div className="jumbotron jumbotron3 jumbotron-fluid">
//                 <Footer />
//             </div>
//         </div>
//     )
// }

// const VoterGuide = () => {
//     return(
//         <div>
//             <AccordionNested />
//             <LogoBar2 />
//             <div className ="jumbotron jumbotron-fluid jumbotron1">
//                 <div className="page-text">
//                     <div className="page-title">
//                         Voter Guide
//                     </div>
//                     <VoterGuidePosts />
//                 </div>
//             </div>
//             <div className="jumbotron jumbotron3 jumbotron-fluid">
//                 <Footer />
//             </div>
//         </div>
//     )
// }


// const Politics = () => {
//     const [singleSelections, setSingleSelections] = useState([]);
//     return(
//         <div>
//             <AccordionNested />
//             <LogoBar2 />
//             <div className ="jumbotron jumbotron-fluid jumbotron1">
//                 <div className="page-text">
//                     <div className="page-title">
//                         Politics
//                     </div>
//                     <Form.Group>
//                         <Typeahead
//                             id="basic-typeahead-single"
//                             labelKey="name"
//                             onChange={setSingleSelections}
//                             options={polpos}
//                             placeholder="Choose a position..."
//                             selected={singleSelections}
//                         />
//                     </Form.Group>
//                     <PoliticsPosts />
//                 </div>
//             </div>
//             <div className="jumbotron jumbotron3 jumbotron-fluid">
//                 <Footer />
//             </div>
//         </div>
//     )
// }

// const ButtonStripArts = () => {
//     return (
//         <Nav justify className='navbar' variant="tabs" color="red" defaultActiveKey="/Arts/Home">
//             <LinkContainer to="/Arts/Home">
//                 <Nav.Item>
//                     <Nav.Link href="/Arts/Home">Home</Nav.Link>
//                 </Nav.Item>
//             </LinkContainer>
//             <LinkContainer to="/Arts/Blog">
//                 <Nav.Item>
//                     <Nav.Link href="/Arts/Blog">Blog</Nav.Link>
//                 </Nav.Item>
//             </LinkContainer>
//             <LinkContainer to="/Arts/Announcements">
//                 <Nav.Item>
//                     <Nav.Link href="/Arts/Announcements">Announcements</Nav.Link>
//                 </Nav.Item>
//             </LinkContainer>
//             <LinkContainer to="/Arts/Community">
//                 <Nav.Item>
//                     <Nav.Link href="/Arts/Community">Community</Nav.Link>
//                 </Nav.Item>
//             </LinkContainer>
//         </Nav>
//     )
// }

// const MA = () => {
//     return(
//         <div>
//             <AccordionNested />
//             <LogoBar2 />
//             <div className ="jumbotron jumbotron-fluid jumbotron1">
//                 <div className="page-text">
//                     <div className="page-title">
//                        Music and the Arts
//                     </div>
//                     <Route path="/Arts">
//                         <ButtonStripArts />
//                         <Switch>
//                             <Route exact path="/Arts" component={() => <Redirect to="/Arts/Home" />}/>
//                             <Route path="/Arts/Home">
//                                 <ArtsPosts />
//                             </Route>
//                             <Route path="/Arts/Blog">
//                                 Blog
//                             </Route>
//                             <Route path="/Arts/Announcements">
//                                 Announcements
//                             </Route>
//                             <Route path="/Arts/Community">
//                                 Community
//                             </Route>
//                         </Switch>
//                     </Route>
//                 </div>
//             </div>
//             <div className="jumbotron jumbotron3 jumbotron-fluid">
//                 <Footer />
//             </div>
//         </div>
//     )
// }

// const App = () => (
//     <MemoryRouter>
//             <Switch>
//                 <Route exact path="/" component={() => <Redirect to="/Home" />}/>
//                 <Route path="/Home">
//                     <HomePage />
//                 </Route>
//                 <Route path="/About">
//                     <About />
//                 </Route>
//                 <Route path="/Contact">
//                     <Contact />
//                 </Route>
//                 <Route path="/Connect">
//                     <Connect />
//                 </Route>
//                 <Route path="/Policy">
//                     <Policy />
//                 </Route>
//                 <Route path="/Transit">
//                     <Transit />
//                 </Route>
//                 <Route path="/Housing">
//                     <HC />
//                 </Route>
//                 <Route path="/Politics">
//                     <Politics />
//                 </Route>
//                 <Route path="/Mentorship">
//                     <MentorshipPage />
//                 </Route>
//                 <Route path="/Arts">
//                     <MA />
//                 </Route>
//                 <Route path="/Voter Guide">
//                     <VoterGuide />
//                 </Route>
//                 <Route path="/Login">
//                     <Container class="p-3">
//                         <AccordionNested />
//                         <LogoBar2 />
//                         <div className ="jumbotron jumbotron-fluid jumbotron1">
//                             <div className="page-text">
//                                 <Login />
//                             </div>
//                         </div>
//                         <div className="jumbotron jumbotron3 jumbotron-fluid">
//                             <Footer />
//                         </div>
//                     </Container>
//                 </Route>
//             </Switch>
//     </MemoryRouter>
// );
const App = () => {
    return (
      <Router>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    )
  }
  
  function Home() {
    useEffect(() => {
        const timeout = setTimeout(() => {
          // 👇️ redirects to an external URL
          window.location.replace('https://linkin.bio/sanjose_strong?fbclid=PAAaa84aFaF-DONs4EKqg_MNpM1QTcxHmsMZ-k8UMluPmuIBs0Dj_DVfaX9K8');
        }, 5);
    
        return () => clearTimeout(timeout);
      }, []);
    
      return <p>Will redirect soon...</p>;
  }

export default App;
